<template>
  <div class="modal-container">
    <a-modal
      width="750px"
      :visible="visible"
      title="新增记录"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        ref="formRef"
        layout="vertical"
        style="width: 100%"
        :model="formData"
        :rules="formRules"
      >
        <a-form-item label="" name="title">
          <a-input
            v-model:value="formData.title"
            placeholder="请输入记录标题"
          />
        </a-form-item>
        <a-form-item label="" name="content">
          <a-textarea
            v-model:value="formData.content"
            placeholder="请输入记录内容"
            showCount
            :maxlength="800"
            :rows="4"
          />
        </a-form-item>
        <div class="chosenList">
          <div
            v-if="chosenContactList?.length > 0"
            @click="handleEmpty()"
            class="cursor-p empty"
          >
            一键清空
          </div>

          <div v-for="(item, index) in chosenContactList" :key="item.id">
            <div class="name">
              {{ item.name }}
              <CloseOutlined @click="deleteName(index)" />
            </div>
          </div>
        </div>
        <a-form-item label="" class="formItemClass">
          <a-input
            v-model:value="contactArr"
            placeholder="请添加联系人"
            @change="contactChange(contactArr)"
            @focus="contactFocus()"
            :allowClear="true"
          >
          </a-input>
          <div v-if="showContactList" class="searchList">
            <div v-for="i in contactList" :key="i.id">
              <div class="selectList">
                <div class="selectName" @click="selectName(i)">
                  {{ i.name }}
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-row style="gap: 16px">
          <!-- :disabled-date="disabledDate"
          :disabled-time="disabledDateTime" -->
          <a-form-item label="" name="recordDate">
            <a-date-picker
              v-model:value="formData.recordDate"
              :getCalendarContainer="triggerNode => triggerNode.parentNode"
              value-format="yyyy-MM-DD HH:mm:ss"
              show-time
              placeholder="请选择时间"
              style="width: 218px"
              class="phoneCalssRight"
              :inputReadOnly="true"
            />
          </a-form-item>
          <a-form-item label="" name="recordType">
            <a-select
              style="width: 218px"
              v-model:value="formData.recordType"
              placeholder="请选择记录类型"
            >
              <template v-for="(item, index) in recordTypeList" :key="index">
                <a-select-option label="" :value="item.itemCode">
                  {{ item.itemNameCn }}
                </a-select-option>
              </template>
            </a-select>
          </a-form-item>
        </a-row>
        <a-form-item label="" class="formFileRow">
          <a-upload
            v-model:file-list="casualFileList"
            list-type="picture-card"
            @preview="filePreview"
            :beforeUpload="fileUploadBefore"
            :action="fileUploadSrc"
            :headers="fileUploadHeaders"
            :data="
              file => ({
                files: file,
                bizType: file.name.slice(file.name.lastIndexOf('.') + 1),
              })
            "
            @change="fileListChange"
          >
            <plus-outlined />
            <div style="margin-top: 8px">上传文件</div>
          </a-upload>
          <a-modal
            :open="filePreviewVisible"
            :title="filePreviewTitle"
            :footer="null"
            @cancel="filePreviewCancel"
          >
            <img alt="example" style="width: 100%" :src="filePreviewImage" />
          </a-modal>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import {
  Form as AForm,
  Row as ARow,
  DatePicker as ADatePicker,
  Select as ASelect,
  Upload as AUpload,
  message,
} from 'ant-design-vue';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons-vue';
import timelineApi from '@/api/timeline';

export default {
  name: 'timeLine',
  components: {
    AForm,
    ARow,
    ADatePicker,
    ASelect,
    AUpload,
    AFormItem: AForm.Item,
    ASelectOption: ASelect.Option,
    CloseOutlined,
    PlusOutlined,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const store = useStore();

    const formRef = ref();
    const formData = ref({
      title: '',
      content: '',
      contactIdList: [],
      recordDate: '',
      recordType: null,
      fileList: [],
    });
    const formRules = {
      title:[
        { required: true, message: '请输入记录标题', trigger: 'change' },
        {  max: 30, message: '记录标题不能超过30字', trigger: 'blur' },
      ],
      content: [
        { required: true, message: '请输入记录内容', trigger: 'change' },
        { min: 1, max: 800, message: '记录内容不能超过800字', trigger: 'blur' },
      ],
      recordType: [
        { required: true, message: '请选择记录类型', trigger: 'change' },
      ],
    };
    const chosenContactList = ref([]);
    const contactList = ref([]);
    const showContactList = ref(false);
    const contactArr = ref('');
    const recordTypeList = ref([]);
    const filePreviewImage = ref('');
    const filePreviewVisible = ref(false);
    const fileUploadSrc = `${process.env.VUE_APP_API_SERVER}connections_treasure_box/obs/obs-files-records/uploadObsFiles`;
    const { token, tenant } = store.state.account;
    const fileUploadHeaders = { token, tenant };
    const filePreviewTitle = ref('');
    const fileUploadingSpace = ref(0);
    const casualFileList = ref([]);

    const fileUploading = ref(false);
    watch(
      () => props.init,
      value => {
        console.log('chosenContactList', chosenContactList);
        chosenContactList.value = [value];
        formData.value.recordDate = '';
      }
    );

    // 防抖函数 做节流处理
    const debounce = (fn, time = 1000) => {
      let timer;
      return (...arges) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          fn(...arges);
        }, time);
      };
    };

    const handleOk = () => {
      if (fileUploading.value) {
        message.warning('图片上传中');
        return;
      }
      chosenContactList.value.map(item => {
        formData.value.contactIdList.push(item.id);
      });
      debounce(submitData());
    };

    const handleCancel = () => {
      contactArr.value = '';
      // context.emit('closeModal', false);
      context.emit('update:visible', false);
      formRef.value.resetFields();
    };

    //数据提交
    const submitData = () => {
      formRef.value.validate().then(() => {
        timelineApi
          .addRecord('', {
            userId: store.state.account.user.id,
            momentTitle: formData.value.title,
            momentContent: formData.value.content,
            momentTime: formData.value.recordDate,
            momentType: formData.value.recordType,
            personnelType: 0,
            obsFilesRecordsList: formData.value.fileList,
            contactIdList: formData.value.contactIdList,
          })
          .then(res => {
            if (res) {
              message.success('新建记录成功');
              formData.value = {
                content: '',
                title: '',
                contactIdList: [],
                recordDate: '',
                recordType: '',
                fileList: [],
              };
              getCurrentData();
              chosenContactList.value = [];
              casualFileList.value = [];
              // context.emit('closeModal', true);
              context.emit('fnOk', false);
            } else {
              message.error('新建失败');
            }
          });
      });
    };

    //联系人聊天框聚焦
    const contactFocus = () => {
      timelineApi.queryContactByName('', {}).then(res => {
        if (res) {
          showContactList.value = true;
          getContactData(res);
        }
      });
    };

    //联系人聊天框失焦
    const contactBlur = () => {
      showContactList.value = false;
    };

    //联系人索引改变
    const contactChange = value => {
      timelineApi
        .queryContactByName('', {
          name: value,
        })
        .then(res => {
          if (res) {
            getContactData(res);
          }
        });
    };

    //获取联系人选择列表数据
    const getContactData = res => {
      let notFoundItems = []; // 存储在list数组中存在但在a数组中不存在的项的新数组
      // 遍历 list 数组
      res.forEach(item => {
        // 使用 JSON.stringify 将对象转换为字符串，然后检查当前项是否在 a 数组中
        if (!chosenContactList.value.some(aItem => aItem.id === item.id)) {
          // 如果不在，将该项添加到 notFoundItems 数组中
          notFoundItems.push(item);
        }
      });
      // 打印在list数组中存在但在a数组中不存在的项
      if (notFoundItems.length > 0) {
        showContactList.value = true;
        contactList.value = notFoundItems;
      } else {
        showContactList.value = false;
      }
    };

    //选择联系人添加
    const selectName = item => {
      if (chosenContactList.value.length > 0) {
        chosenContactList.value.forEach(i => {
          if (i.id !== item.id) {
            chosenContactList.value.push(item);
          }
        });
        // 数组对象去重的做法
        let unique = {};
        chosenContactList.value.forEach(item => {
          unique[item['id']] = item;
        });
        chosenContactList.value = Object.values(unique);
      } else {
        chosenContactList.value.push(item);
      }

      contactArr.value = '';
      contactList.value = [];
      showContactList.value = false;
    };

    //删除添加的联系人
    const deleteName = index => {
      chosenContactList.value.splice(index, 1);
    };

    //获取当前时间
    const getCurrentData = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : '0' + (now.getMonth() + 1);
      const day = now.getDate() >= 10 ? now.getDate() : '0' + now.getDate();
      const hour = now.getHours() >= 10 ? now.getHours() : '0' + now.getHours();
      const minute =
        now.getMinutes() >= 10 ? now.getMinutes() : '0' + now.getMinutes();
      const second = '00';

      formData.value.recordDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    };

    //设置日期禁用范围
    const disabledDate = current => {
      return current && current < moment().subtract(1, 'days');
    };

    //区间选择函数
    const range = (start, end) => {
      const result = [];

      for (let i = start; i < end; i++) {
        result.push(i);
      }

      return result;
    };

    //设置时间禁用范围
    const disabledDateTime = () => {
      return {
        disabledSeconds: () => range(1, 60),
      };
    };

    //获取记录类型列表
    const getRecordType = () => {
      timelineApi.getRecordType('', {}).then(res => {
        if (res) {
          recordTypeList.value = res.filter(item => item.id != 2095);
        }
      });
    };

    //文件上传前检查
    const fileUploadBefore = file => {
      let filesize = file.size;

      fileUploading.value = true;

      if (
        file.type == 'application/octet-stream' ||
        file.type == 'application/x-msdownload' ||
        file.type == 'application/javascript' ||
        file.type == 'application/vnd.ms-word.document.macroenabled.12' ||
        file.type == 'application/vnd.ms-excel.sheet.macroenabled.12' ||
        file.type == 'application/x-shockwave-flash'
      ) {
        fileUploading.value = false;
        return false;
      }

      timelineApi.getUserFreeSpace('', {}).then(res => {
        if (res) {
          let allSpace = res.allSpace;
          let remainingSpace = res.remainingSpace;
          console.log(
            '用户总存储空间:' + allSpace + ';已用空间:' + remainingSpace
          );

          if (remainingSpace + fileUploadingSpace.value + filesize > allSpace) {
            fileUploading.value = false;
            return false;
          }
        }
      });
    };

    //文件列表上传回调
    const fileListChange = e => {
      // console.log(e.file);
      if (e.file.status === 'done') {
        if (e.file.response.code == 0) {
          formData.value.fileList.push(e.file.response.data);
          fileUploading.value = false;
        }
      }
      if (e.file.status === 'removed') {
        // console.log('file removed');
        let fileId = e.file.response.data.id;

        formData.value.fileList = formData.value.fileList.filter(
          item => item.id != fileId
        );
      }
    };

    //文件转base64
    const getBase64 = file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    };

    //关闭文件预览
    const filePreviewCancel = () => {
      filePreviewVisible.value = false;
      filePreviewTitle.value = '';
    };

    //打开文件预览
    const filePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      filePreviewImage.value = file.url || file.preview;
      filePreviewVisible.value = true;
      filePreviewTitle.value =
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
    };
    const handleEmpty = () => {
      chosenContactList.value = [];
    };

    onMounted(() => {
      getRecordType();
      getCurrentData();
    });

    return {
      store,
      formRef,
      formData,
      formRules,
      chosenContactList,
      contactList,
      showContactList,
      contactArr,
      recordTypeList,
      filePreviewImage,
      filePreviewVisible,
      fileUploadSrc,
      fileUploadHeaders,
      filePreviewTitle,
      fileUploadingSpace,
      casualFileList,
      fileUploading,
      handleEmpty,
      handleCancel,
      contactFocus,
      contactBlur,
      contactChange,
      selectName,
      deleteName,
      disabledDate,
      disabledDateTime,
      fileUploadBefore,
      fileListChange,
      filePreviewCancel,
      filePreview,
      handleOk,
    };
  },
};
</script>

<style lang="less" scoped>
.formItemClass {
  position: relative;
}

.chosenList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 4px;
  margin-top: 16px;
  max-height: 68px;
  overflow: auto;

  .name {
    width: 88px;
    height: 24px;
    border-radius: 4px;
    //background: #FFFFFF;
    background: #fffaf6;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 8px 8px 0px;
  }
}

.searchList {
  max-height: 164px;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 24px;
  z-index: 2;
  margin-top: 16px;
  border: 1px solid #eeeff5;
  background: #fff;
  border-radius: 4px;

  .selectList {
    .selectName {
      height: 40px;
      //border-radius: 4px;
      background: #f5f6fa;
      font-size: 12px;
      line-height: 20px;
      //padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0px 8px 8px 0px;
      //padding: 10px 16px;
      cursor: pointer;
      padding-left: 16px;

      &:hover {
        background: #fffaf6;
      }
    }
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.empty {
  color: #ff7b00;
  margin-right: 12px;
}
</style>
