<template>
  <a-modal
    :visible="visible"
    :title="type === 'openPinci' ? '修改联系频次' : '设置联系频次'"
    :mask-closable="false"
    :width="600"
    centered
    @ok="handleOk"
    @cancel="handleCancel"
    forceRender
    :destroyOnClose="true"
  >
    <a-form
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      layout="vertical"
    >
      <div
        v-for="item in frequenctList"
        :key="item.id"
        :value="item.itemCode"
        class="listClass"
        :class="{ activeBackgroud: item.id === listId }"
        @click="handleElement(item)"
      >
        <img
          src="@/assets/images/box/frequenct.png"
          alt=""
          class="frequenct-img"
          :class="{ activeClass: item.id === listId }"
        />
        {{ item.itemNameCn }}
      </div>
    </a-form>
    <template v-slot:footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary"  @click="handleOk" :loading="loading">确定</a-button>
    </template>
  </a-modal>
</template>
<script>
import { getCurrentInstance, reactive, toRaw, toRefs, watch } from 'vue';
import { Form, message } from 'ant-design-vue';
import contactAll from '@/api/contactAll';
import { useStore } from 'vuex';

// import { validMobile, validEmail } from '@/utils/validation';
// import {
//   PlusOutlined,
// } from '@ant-design/icons-vue';

export default {
  components: {
    AForm: Form,
    // AFormItem: Form.Item,
    // ASelect: Select,
    // ASelectOption: Select.Option,
    // PlusOutlined,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const userId = store.state.account.user.id;
    const form = reactive({
      id: [],
      userId: userId,
      frequency: '',
      selectAll: null,
      pageDTO: {},
    });

    const state = reactive({
      frequenctList: [],
      listId: '',
      type: '',
      loading: false,
    });

    watch(
      () => props.init,
      value => {
        console.log('contactData value 跨页', value);
        Object.assign(form.id, value.id);
        form.selectAll = value.selectAll
        form.pageDTO = value.pageDTO

      }
    );

    const handleCancel = () => {
      console.log('false ')
      context.emit('update:visible', false);
    };
    contactAll.getTree('', { code: 'FREQUENCY' }).then(res => {
      Object.assign(state.frequenctList, res);
    });

    proxy.$bus.on('handleOpenPinci', value => {
      console.log('handleOpenPinci', value);
      if (value.type === 'openPinci') {
        state.type = 'openPinci';
      }
    });

    return {
      ...toRefs(state),
      form,
      handleCancel,
      // 分类列表保存方法调用接口的方法和事件
      handleOk: () => {
        state.loading = true
        contactAll.updateUserInfo('', toRaw(form)).then(() => {
          message.success('操作成功');
          // 需要对列表做全选的刷新处理，去掉全选的勾选状态
          context.emit('fnOk', false);
          state.listId = ''
          state.loading = false
        }).catch(() => {
          message.error('网络异常，请联系技术人员处理')
          state.loading = false;
        });
      },
      handleElement: item => {
        state.listId = item.id;
        form.frequency = item.itemCode;
      },
    };
  },
};
</script>
<style lang="less" scoped>
.listClass {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee;
  padding: 13px 44px;
  align-items: center;
  cursor: pointer;
}
.frequenct-img {
  //margin-right: 12px;
  display: none;
  width: 13px;
  height: 13px;
  position: absolute;
  left: 42px;
}
.activeClass {
  display: block;
}
.activeBackgroud {
  background: rgba(255, 123, 0, 0.08);
}
</style>
