<template>
  <div class="iss-main schedule">
    <a-row>
      <a-col :span="12" class="titleLeft">
        <div class="toDay-title">今天</div>
        <div class="toDay-hello">{{ userData?.userName }}，下午好！</div>
      </a-col>
      <a-col :span="12" class="titleRight">
        <!--        <img src="@/assets/images/box/dataImg.png" alt="" class="dataImg" />-->
        <!--        11月8日-->
      </a-col>
    </a-row>
    <div class="gutter-example guidelines">
      <a-row :gutter="16">
        <a-col
          class="gutter-row"
          :span="8"
          @click="goToDashboard({ importStatus: 1 })"
        >
          <a-row type="flex" justify="start" class="gutter-box kapianOne">
            <a-col :span="3">
              <div class="titleOneLeft">
                <img
                  src="@/assets/images/box/titleOneImg.png"
                  alt=""
                  class="titleOneImg"
                />
              </div>
            </a-col>
            <a-col :span="18">
              <div class="titleOneZhong">
                <div class="titleOne">待清洗联系人</div>
                <div class="fn">清洗您导入后还未清洗的联系人数据</div>
              </div>
            </a-col>
            <a-col :span="3"
              ><div class="titleOneRight">{{ numOne }}</div></a-col
            >
          </a-row>
        </a-col>
        <a-col class="gutter-row" :span="8" @click="toMissionCenter">
          <a-row type="flex" justify="start" class="gutter-box kapianTwo">
            <a-col :span="2">
              <div class="titleTwoLeft">
                <img
                  src="@/assets/images/box/titleTwoImg.png"
                  alt=""
                  class="titleTwoImg"
                />
              </div>
            </a-col>
            <a-col :span="17">
              <div class="titleTwoZhong">
                <div class="titleTwo">新手清单</div>
                <div class="fn">充分利用人脉宝盒管理您的人脉</div>
              </div>
            </a-col>
            <a-col :span="5">
              <div class="titleTwoRight">{{ finishCount }}</div>
            </a-col>
          </a-row>
        </a-col>
        <a-col class="gutter-row" :span="8">
          <a-row type="flex" justify="start" class="gutter-box kapianThree">
            <a-col :span="3">
              <div class="titleThreeLeft">
                <img
                  src="@/assets/images/box/titleThreeImg.png"
                  alt=""
                  class="titleThreeImg"
                />
              </div>
            </a-col>
            <a-col :span="15">
              <div class="titleThreeZhong">
                <div class="titleThree">人脉宝盒AI</div>
                <div class="fn">AI辅助生成与联系人沟通内容</div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="titleThreeRight">立即体验</div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>

    <div class="contactsToday" v-if="isShow">
      <div class="continue">
        <component :is="icons['InfoCircleOutlined']" />
        同步的联系人还未完成，请继续处理。
      </div>
      <div class="instantly" @click="goToDashboard({ importStatus: 0 })">
        立即处理
      </div>
    </div>

    <!--  有最近待办就不显示这个，待办为空时展示  -->
    <div class="goodDone" v-if="showImg">
      <img src="@/assets/images/box/goodDone.png" alt="" class="goodDoneImg" />
      <div class="goodText1">做得很好！</div>
      <div class="goodText2">都完成了，继续保持！</div>
    </div>

    <!--  待办  -->
    <iss-reminders v-else />
  </div>
</template>

<script>
import { Row, Col } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import * as icons from '@ant-design/icons-vue';
import todayApi from '@/api/today';
import { onMounted, reactive, toRefs, onBeforeMount, nextTick } from 'vue';
import dashboardApi from '@/api/dashboard';
import IssReminders from '@/views/today/homePage/reminders';
import { local } from '@/utils/storage';
import personalApi from '@/api/personal';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    Icon: icons,
    IssReminders,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const userId = store.state.account.user.id;
    const state = reactive({
      numOne: '0',
      isShow: false,
      showImg: false,
      finishCount: '0',
      userData: {},
    });

    const getHomeShowData = () => {
      todayApi
        .getHomeShowData('', {
          userId: store.state.account.user.id,
        })
        .then(res => {
          state.numOne = res.pend;
          state.finishCount = res.finishCount;
        });
    };
    const getImportSituation = () => {
      todayApi
        .getContactImportSituation('', {
          userId: store.state.account.user.id,
        })
        .then(res => {
          // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
          if (res === 'untreated') {
            state.isShow = true;
            // router.push('/today');
          } else if (res === 'incomplete') {
            state.isShow = true;
          }
        });
    };

    const getBehaviorPage = type => {
      //  获取用户导入行为页面信息
      dashboardApi
        .getBehaviorPage({
          userId: store.state.account.user.id,
          importSituation: type,
        })
        .then(res => {
          // 有数据就去我设置的页面，否去dashboard页面
          if (res) {
            // console.log('shuju shuju', res);
            router.push({
              path: res,
              query: {
                importStatus: type,
              },
            });
          } else {
            router.push({
              name: 'dashboard',
              query: {
                importStatus: type,
              },
            });
          }
        });
    };

    const toMissionCenter = () => {
      router.push({
        path: '/mission-center',
      });
    };

    // 获取用户最新个人信息
    const getUserInfo = () => {
      personalApi.getItemById('', userId).then(data => {
        if (data) {
          // 存储用户信息
          state.userData = data
          store.commit('account/setUserHeader', data);
        }

      });
    };

    onBeforeMount(() => {
      getImportSituation();
    });

    onMounted(() => {
      getHomeShowData();
      getUserInfo();
      if (
        local.get('dataSource') ||
        local.get('birthdayData') ||
        local.get('contactData') ||
        local.get('nextWeekData')
      ) {
        return (state.showImg = false);
      } else {
        return (state.showImg = true);
      }


    });

    nextTick(() => {});

    return {
      icons,
      getImportSituation,
      toMissionCenter,
      ...toRefs(state),
      goToDashboard(type) {
        const { importStatus } = type;
        if (importStatus === 0) {
          // 跳转至恭喜你,同步成功页面
          getBehaviorPage(0);
          // router.push(`/dashboard?importStatus=${importStatus}`);
        } else if (importStatus === 1) {
          getBehaviorPage(1);
          // router.push(`/dashboard?importStatus=${importStatus}`);
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  padding: 32px 54px;
}
// .schedule {
//   position: relative;
// }

.guidelines {
  text-align: center;
  margin-top: 22px;
  color: #999999;
}
.toDay-hello {
  margin-top: 12px;
  color: #666666;
  font-size: 16px;
}
.toDay-title {
  color: #333333;
  font-weight: 500;
  font-size: 32px;
}
.titleRight {
  color: #ff8f1f;
  margin-top: 21px;
  text-align: right;
  font-size: 14px;
}
.kapianOne {
  background: #f2faf5;
  border-radius: 11px 11px 11px 11px;
  opacity: 1;
  padding: 19px 21px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .titleOne {
    color: #2caf5e;
  }
}
.kapianTwo {
  background: #fff7ea;
  border-radius: 11px 11px 11px 11px;
  opacity: 1;
  padding: 19px 21px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  .titleTwo {
    color: #ff7b00;
  }
}
.kapianThree {
  background: rgba(89, 121, 248, 0.08);
  border-radius: 11px 11px 11px 11px;
  opacity: 1;
  padding: 19px 21px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  .titleThree {
    color: #5979f8;
  }
}
.titleOneLeft {
  background: #ffffff;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .titleOneImg {
    margin: 8px 6px;
  }
}
.titleOneZhong {
  margin-left: 12px;
  font-size: 14px;
  text-align: left;
}
.titleOneRight {
  background: #2caf5e;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  padding: 4px 0px;
  color: #ffffff;
}
.titleTwoLeft {
  background: #ffffff;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .titleTwoImg {
    margin: 8px 6px;
  }
}
.titleTwoZhong {
  margin-left: 12px;
  font-size: 14px;
  text-align: left;
}

.titleTwoRight {
  background: #ff7b00;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  padding: 4px 0px;
  color: #ffffff;
}

.titleThreeLeft {
  background: #ffffff;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .titleThreeImg {
    margin: 8px 6px;
  }
}
.titleThreeZhong {
  margin-left: 12px;
  font-size: 14px;
  text-align: left;
}
.titleThreeRight {
  background: #5979f8;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  color: #ffffff;
  padding: 6px 4px;
  color: #ffffff;
  font-size: 15px;
}
.goodDone {
  text-align: center;
  margin-top: 120px;
  .goodText1 {
    font-size: 24px;
    color: #3d3d3d;
  }
  .goodText2 {
    font-size: 19px;
    color: #97a5ce;
  }
}
.contactsToday {
  background: rgba(250, 81, 81, 0.08);
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-top: 16px;
  .continue {
    font-size: 12px;
    color: #fa5151;
  }
  .instantly {
    color: #ffffff;
    font-size: 10px;
    background: #fa5151;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
  }
}
.fn {
  font-size: 12px;
}
</style>
