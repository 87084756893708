<template>
  <div class="list-wrapper">
    <div class="reminderTitle">
      <div class="title">
        <div>最近待办（{{ dataSource.length }}）</div>
        <!--   筛选区  -->
        <div class="searchClass">
          <a-space class="mb-16" :gutter="16">
            <a-select
              v-model:value="searchType"
              placeholder="筛选"
              @change="handleSearchTask"
              style="min-width: 120px"
              class="selClass"
              :allowClear="false"
            >
              <a-select-option
                v-for="item in items"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>

            <a-range-picker
              v-model:value="form.dateStrings"
              :allowClear="false"
              :inputReadOnly="true"
              @change="handleChangeByDate"
              style="width: 226px"
            >
              <template #suffixIcon><calendar-outlined /></template>
            </a-range-picker>
          </a-space>
        </div>
      </div>
      <div class="gotoAll" @click="gotoAll('/today/schedule')">查看全部</div>
    </div>
    <a-checkbox-group
      v-model:value="selectedKeys"
      @change="handleChange"
      style="width: 100%"
    >
      <a-list
        item-layout="horizontal"
        :loading="loading"
        :data-source="dataSource"
      >
        <template #renderItem="{ item }">
          <a-list-item
            :class="{
              showBac: item.isShow,
              lateBac: item.showLate,
            }"
          >
            <a-list-item-meta>
              <template #title>
                <CaretDownOutlined
                  class="normal-car-icon"
                  @click="enter(item)"
                  style="font-size: 12px; margin-left: 10px"
                  v-if="item.subItemsList?.length > 0"
                />
                <!-- <img
                  v-else-if="!item.isShow"
                  class="list-dot-icon"
                  src="@/assets/images/reminder/listdot.png"
                  @click="enter(item)"
                />
                <img
                  v-else
                  class="list-dot-icon"
                  src="@/assets/images/reminder/listdot-active.png"
                  @click="enter(item)"
                /> -->

                <div class="listNameA" @click="handleName(item)">
                  {{ item.content }}
                </div>
              </template>
              <!--              <template #avatar> </template>-->
              <template #description>
                <!--          todo 头像放置处     -->
                <div class="descriptionA">
                  <div class="pink">
                    <!--  脉圈样式 点击这里才是跳转到脉圈详情页面 -->
                    <div class="avatarClass" v-if="item.personnelType === 1">
                      <component
                        :is="icons[item?.connections?.icon || 'UserOutlined']"
                        class="iconClass"
                        @click="
                          handleGoToContact(
                            item.connections,
                            item.personnelType
                          )
                        "
                      >
                      </component>
                      <div
                        class="textMl mr-8"
                        @click="
                          handleGoToContact(
                            item.connections,
                            item.personnelType
                          )
                        "
                      >
                        {{ item?.connections?.connectionsName }}
                      </div>
                    </div>

                    <div v-if="item.personnelType === 1" class="flexCalssA">
                      <div
                        class="avatarClass"
                        v-for="i in item?.personnelVoList.slice(0, 5)"
                        :key="i.id"
                      >
                        <a-avatar
                          v-if="i.avatar"
                          :src="setAvatar(i.avatar)"
                          @click="handleGoToContact(i)"
                          class="headerUrl"
                        />
                        <div
                          v-else
                          @click="handleGoToContact(i)"
                          class="headerText headerTextC"
                        >
                          <div v-if="i.name">
                            {{ i.name[0] }}
                          </div>
                        </div>
                        <div
                          class="textMl"
                          @click="handleGoToContact(i)"
                          v-if="item?.personnelVoList.length === 1"
                        >
                          {{ i.name }}
                        </div>
                      </div>
                    </div>

                    <div v-else class="flexCalssA">
                      <div
                        class="avatarClass"
                        v-for="i in item?.personnelVoList.slice(0, 12)"
                        :key="i.id"
                      >
                        <a-avatar
                          v-if="i.avatar"
                          :src="setAvatar(i.avatar)"
                          @click="handleGoToContact(i)"
                          class="headerUrl"
                        />
                        <div
                          v-else
                          @click="handleGoToContact(i)"
                          class="headerText"
                        >
                          <div v-if="i.name">
                            {{ i.name[0] }}
                          </div>
                        </div>
                        <div
                          class="textMl"
                          @click="handleGoToContact(i)"
                          v-if="item?.personnelVoList.length === 1"
                        >
                          {{ i.name }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--          操作按钮放置处    -->
                  <div class="actions">
                    <div class="statisticMun">
                      <div class="item-title">
                        <img
                          src="@/assets/images/reminder/fenbu.png"
                          alt=""
                          class="fenbuImg"
                        />
                        {{
                          item.subCompletedCount ? item.subCompletedCount : 0
                        }}/{{
                          item.subItemsList?.length
                            ? item.subItemsList?.length
                            : 0
                        }}
                      </div>
                    </div>

                    <!--  纯日期时间展示，鼠标移出后隐藏  -->
                    <div class="statistic-item">
                      <div class="item-title">
                        {{ handleReminderTime(item?.reminderTime) }}
                      </div>
                    </div>
                    <!-- v-if="item.isShow" -->
                    <div class="ribbon">
                      <a-tooltip placement="top">
                        <template #title>
                          <span>编辑待办</span>
                        </template>
                        <div class="dropDown" @click="handleEdit(item, 'edit')">
                          <!--                        <img-->
                          <!--                          src="@/assets/images/box/export.png"-->
                          <!--                          alt=""-->
                          <!--                          class="export-img"-->
                          <!--                        />-->
                          <img
                            src="@/assets/images/reminder/editT.png"
                            alt=""
                            class="editImg"
                          />
                        </div>
                      </a-tooltip>
                      <a-tooltip placement="top">
                        <template #title>
                          <span>删除待办</span>
                        </template>
                        <div class="timeTime" @click="handleDelete(item)">
                          <img
                            src="@/assets/images/reminder/delT.png"
                            alt=""
                            class="deleteImg"
                          />
                        </div>
                      </a-tooltip>
                      <a-tooltip placement="top">
                        <template #title>
                          <span>全部完成</span>
                        </template>
                        <div class="gouGou" @click="handleGou(item)">
                          <CheckOutlined class="gou" />
                        </div>
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </template>
            </a-list-item-meta>
          </a-list-item>

          <!--       嵌套数据渲染  -->
          <div
            v-if="item.isShow"
            :class="{
              showBac: item.isShow,
              lateBac: item.showLate,
            }"
          >
            <div
              class="listItem"
              v-for="(listItem, index) in item.subItemsList"
              :key="listItem.id"
              :class="{ showBor: item.subItemsList.length - 1 === index }"
            >
              <!--         class="ml-20 mr-13"    -->
              <!-- <div class="ml-10">
                <a-checkbox
                  v-model:value="listItem.id"
                  v-model:checked="listItem.completed"
                  @change="handleCheck(item, listItem)"
                />
              </div> -->

              <!--               -->
              <div
                class="listName listItemName"
                :class="{ showLine: listItem.completed }"
              >
                {{ listItem.subContent }}
              </div>
              <!--           头像、描述、操作放置处     -->
              <div class="description">
                <!--          操作按钮放置处    -->
                <div class="actions">
                  <!--  纯日期时间展示，鼠标移出后隐藏  -->
                  <div class="statistic-item">
                    <div class="item-title">
                      {{ handleReminderTime(listItem.reminderTime) }}
                    </div>
                  </div>
                  <!-- v-if="item.isShow" -->
                  <div class="ribbon">
                    <a-tooltip placement="top" v-if="listItem.completed">
                      <template #title>
                        <span>撤回完成</span>
                      </template>
                      <div
                        class="dropDown"
                        @click="handleCheck(item, listItem, false)"
                      >
                        <RedoOutlined class="editImg" />
                      </div>
                    </a-tooltip>
                    <a-tooltip placement="top" v-else>
                      <template #title>
                        <span>完成待办</span>
                      </template>
                      <div
                        class="gouGou"
                        @click="handleCheck(item, listItem, true)"
                      >
                        <CheckOutlined class="gou" />
                      </div>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </a-list>
    </a-checkbox-group>
  </div>

  <!-- 最近生日  -->
  <div class="list-wrapperC">
    <div class="reminderTitle">
      <div class="title">最近生日（{{ birthdayData.length }}）</div>
      <div class="gotoAll" @click="gotoAll('/today/birthday')">查看全部</div>
    </div>

    <a-checkbox-group
      v-model:value="selectedKeys"
      @change="handleChange"
      style="width: 100%"
    >
      <!--    生日  -->
      <a-list
        item-layout="horizontal"
        :loading="loading"
        :data-source="birthdayData"
        :locale="{ emptyText: '暂无联系人生日' }"
      >
        <template #renderItem="{ item }">
          <a-list-item
            :class="{
              showBac: item.isShow,
            }"
          >
            <a-list-item-meta>
              <template #title>
                <CaretDownOutlined
                  class="normal-car-icon"
                  @click="enterBirthday(item)"
                  style="font-size: 12px; margin-left: 10px"
                  v-if="
                    item.contactTodoMatterVoList &&
                    item.contactTodoMatterVoList?.length > 0
                  "
                />
                <!-- <img
                  v-else-if="!item.isShow"
                  class="list-dot-icon"
                  src="@/assets/images/reminder/listdot.png"
                  @click="enterBirthday(item)"
                />
                <img
                  v-else
                  class="list-dot-icon"
                  src="@/assets/images/reminder/listdot-active.png"
                  @click="enterBirthday(item)"
                /> -->

                <div class="titleName">
                  <a-avatar
                    v-if="item.avatar"
                    :src="setAvatar(item.avatar)"
                    @click="handleGoToContact(item)"
                    class="headerUrl"
                  />
                  <div
                    v-else
                    @click="handleGoToContact(item)"
                    class="headerText"
                  >
                    <div v-if="item.name">
                      {{ item.name[0] }}
                    </div>
                  </div>
                  <div class="listNameBirthday">
                    {{ item.name }}
                  </div>
                  <div class="avatarClassC">
                    <div class="textMl mr-24">
                      {{ item.company }}
                    </div>
                  </div>
                  <div class="avatarClassC">
                    <div class="textMl mr-8">
                      {{ item.position }}
                    </div>
                  </div>
                </div>
              </template>

              <template #description>
                <!--          todo 头像放置处     -->
                <div class="description">
                  <!--       todoCompleteSum 待办事项完成数量/todoCount 待办事项总数           -->
                  <div class="total">
                    <!--                   缺图标   -->
                    <!-- {{ item.todoCompleteSum ? item.todoCompleteSum : 0 }}/ -->
                    <!-- {{ item.todoCount ? item.todoCount : 0 }} -->
                  </div>

                  <div class="pinkC">
                    <!--  纯日期时间展示，鼠标移入后隐藏   -->
                    <div class="statistic-item">
                      <img src="@/assets/images/reminder/birthday.png" alt="" />
                      <div class="item-title">
                        {{ handleReminderTimeBir(item?.birthday) }}
                      </div>
                    </div>
                    <div class="ribbonC">
                      <!--                生日联系人添加待办      -->
                      <a-tooltip placement="top">
                        <template #title>
                          <span>添加待办</span>
                        </template>
                        <div
                          class="gouGou"
                          @click="handleOpenAddBirthday(item, 'add')"
                        >
                          <PlusOutlined class="gou" />
                        </div>
                      </a-tooltip>
                      <!--                todo 点击联系人勾，点击勾选在今天todo列表中完成该生日提醒不再展示。     -->
                      <!-- <a-tooltip placement="top">
                        <template #title>
                          <span>完成待办</span>
                        </template>
                        <div class="gouGou" @click="handleContent(item)">
                          <CheckOutlined class="gou" />
                        </div>
                      </a-tooltip> -->
                    </div>
                  </div>
                </div>
              </template>
            </a-list-item-meta>
          </a-list-item>

          <!--       嵌套数据渲染    -->
          <div
            class="ifMous"
            v-if="item.isShow"
            :class="{ showBac: item.isShow }"
          >
            <div v-if="item.contactTodoMatterVoList" class="ifClass">
              <!--           todo 鼠标移入联系人 需要展示该联系人下的主待办和子级待办     -->
              <div
                v-for="(listItem, index) in item.contactTodoMatterVoList"
                :key="listItem.id"
                :class="{
                  showBor: item.contactTodoMatterVoList.length - 1 === index,
                }"
              >
                <div class="birthdayZhu">
                  <CaretDownOutlined
                    class="sp-car-icon"
                    :class="{ showCac: listItem.showBir }"
                    @click="
                      enterBirthdayC(item.contactTodoMatterVoList, listItem)
                    "
                    style="font-size: 12px; margin: 0 10px"
                    v-if="
                      listItem.subItemsList && listItem.subItemsList.length > 0
                    "
                  />

                  <!-- <img
                    v-else-if="!listItem.isShow"
                    class="list-dot-icon"
                    src="@/assets/images/reminder/listdot.png"
                    @click="
                      enterBirthdayC(item.contactTodoMatterVoList, listItem)
                    "
                  />
                  <img
                    v-else
                    class="list-dot-icon"
                    src="@/assets/images/reminder/listdot-active.png"
                    @click="
                      enterBirthdayC(item.contactTodoMatterVoList, listItem)
                    "
                  /> -->
                  <div
                    class="listNameC birContent"
                    :class="{ showLine: listItem.completed }"
                    @click="handleName(listItem)"
                  >
                    {{ listItem.content }}
                  </div>
                  <!--    v-if="listItem.personnelType === 1"   -->
                  <div class="flexCalssC" v-if="listItem.personnelType === 1">
                    <div class="personnelVoListCalss">
                      <div
                        class="avatarClass"
                        v-for="itemT in listItem?.personnelVoList.slice(0, 12)"
                        :key="itemT.id"
                      >
                        <a-avatar
                          v-if="itemT.avatar"
                          :src="setAvatar(itemT.avatar)"
                          @click="handleGoToContact(itemT)"
                          class="headerUrl"
                        />
                        <div
                          v-else
                          @click="handleGoToContact(itemT)"
                          class="headerText headerTextC"
                        >
                          <div v-if="itemT.name">
                            {{ itemT.name[0] }}
                          </div>
                        </div>
                        <div
                          class="textMl"
                          @click="handleGoToContact(itemT)"
                          v-if="listItem?.personnelVoList.length === 1"
                        >
                          {{ itemT.name }}
                        </div>
                      </div>
                    </div>

                    <!--               todo 生日主待办操作    -->
                    <div class="descriptionC">
                      <!--          操作按钮放置处    -->
                      <div class="actions">
                        <div class="statisticMun">
                          <div class="item-title">
                            <img
                              src="@/assets/images/reminder/fenbu.png"
                              alt=""
                              class="fenbuImg"
                            />
                            <!--                      todoCompleteSum 待办事项完成数量/ subItemsList 待办事项总数     -->
                            {{
                              listItem.subCompletedCount ? listItem.subCompletedCount : 0
                            }}/{{
                              listItem.subItemsList?.length
                                ? listItem.subItemsList?.length
                                : 0
                            }}
                          </div>
                        </div>

                        <!--  纯日期时间展示，鼠标移出后隐藏  -->
                        <div class="statistic-item">
                          <div class="item-title">
                            {{ handleReminderTime(listItem?.reminderTime) }}
                          </div>
                        </div>
                        <!-- v-if="listItem.showBir" -->
                        <div class="ribbon">
                          <!--                生日父待办，编辑显示生日新增弹窗           -->
                          <a-tooltip placement="top">
                            <template #title>
                              <span>编辑待办</span>
                            </template>
                            <div
                              class="dropDown"
                              @click="handleOpenAddBirthday(listItem, 'edit')"
                            >
                              <img
                                src="@/assets/images/reminder/editT.png"
                                alt=""
                                class="editImg"
                              />
                            </div>
                          </a-tooltip>
                          <!--  生日主待办删除 -->
                          <a-tooltip placement="top">
                            <template #title>
                              <span>删除待办</span>
                            </template>
                            <div
                              class="timeTime"
                              @click="handleDelete(listItem)"
                            >
                              <img
                                src="@/assets/images/reminder/delT.png"
                                alt=""
                                class="deleteImg"
                              />
                            </div>
                          </a-tooltip>
                          <!--  生日主待办完成 -->
                          <a-tooltip placement="top">
                            <template #title>
                              <span>全部完成</span>
                            </template>
                            <div class="gouGou" @click="handleGou(listItem)">
                              <CheckOutlined class="gou" />
                            </div>
                          </a-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flexCalssC" v-else>
                    <div class="personnelVoListCalss">
                      <div
                        class="avatarClass"
                        v-for="itemT in listItem?.personnelVoList.slice(0, 12)"
                        :key="itemT.id"
                      >
                        <a-avatar
                          v-if="itemT.avatar"
                          :src="setAvatar(itemT.avatar)"
                          @click="handleGoToContact(itemT)"
                          class="headerUrl"
                        />
                        <div
                          v-else
                          @click="handleGoToContact(itemT)"
                          class="headerText"
                        >
                          <div v-if="itemT.name">
                            {{ itemT.name[0] }}
                          </div>
                        </div>
                        <div
                          class="textMl"
                          @click="handleGoToContact(itemT)"
                          v-if="listItem?.personnelVoList.length === 1"
                        >
                          {{ itemT.name }}
                        </div>
                      </div>
                    </div>

                    <!--               todo 生日主待办操作    -->
                    <div class="descriptionC">
                      <!--          操作按钮放置处    -->
                      <div class="actions">
                        <div class="statisticMun">
                          <div class="item-title">
                            <img
                              src="@/assets/images/reminder/fenbu.png"
                              alt=""
                              class="fenbuImg"
                            />
                            <!--                      todoCompleteSum 待办事项完成数量/ subItemsList 待办事项总数     -->
                            {{
                              listItem.subCompletedCount ? listItem.subCompletedCount : 0
                            }}/{{
                              listItem.subItemsList?.length
                                ? listItem.subItemsList?.length
                                : 0
                            }}
                          </div>
                        </div>

                        <!--  纯日期时间展示，鼠标移出后隐藏  -->
                        <div class="statistic-item">
                          <div class="item-title">
                            {{ handleReminderTime(listItem?.reminderTime) }}
                          </div>
                        </div>
                        <!-- v-if="listItem.showBir" -->
                        <div class="ribbon">
                          <!--                生日父待办，编辑显示生日新增弹窗           -->
                          <a-tooltip placement="top">
                            <template #title>
                              <span>编辑待办</span>
                            </template>
                            <div
                              class="dropDown"
                              @click="handleOpenAddBirthday(listItem, 'edit')"
                            >
                              <img
                                src="@/assets/images/reminder/editT.png"
                                alt=""
                                class="editImg"
                              />
                            </div>
                          </a-tooltip>
                          <!--                生日主待办删除             -->
                          <a-tooltip placement="top">
                            <template #title>
                              <span>删除待办</span>
                            </template>
                            <div
                              class="timeTime"
                              @click="handleDelete(listItem)"
                            >
                              <img
                                src="@/assets/images/reminder/delT.png"
                                alt=""
                                class="deleteImg"
                              />
                            </div>
                          </a-tooltip>
                          <!--                   生日主待办完成       -->
                          <a-tooltip placement="top" v-if="!listItem.completed">
                            <template #title>
                              <span>全部完成</span>
                            </template>
                            <div class="gouGou" @click="handleGou(listItem)">
                              <CheckOutlined class="gou" />
                            </div>
                          </a-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--      todo   生日的子待办放置处     -->
                <div
                  v-if="listItem.showBir"
                  :class="{ showBac: listItem.isShow }"
                  class="showBir"
                >
                  <div
                    class="listItemC"
                    v-for="(itemBir, index) in listItem.subItemsList"
                    :key="itemBir.id"
                    :class="{
                      showBor: listItem.subItemsList.length - 1 === index,
                    }"
                  >
                    <!--                  生日子级待办完成  主待办id  listItem   -->
                    <!-- <div class="ml-10">
                      <a-checkbox
                        v-model:value="itemBir.id"
                        v-model:checked="itemBir.completed"
                        @change="handleCheck(listItem, itemBir)"
                      />
                    </div> -->

                    <!--               -->
                    <div
                      class="listName listItemName"
                      :class="{ showLine: itemBir.completed }"
                    >
                      {{ itemBir.subContent }}
                    </div>
                    <!--           头像、描述、操作放置处     -->
                    <div class="description">
                      <!--          操作按钮放置处    -->
                      <div class="actions">
                        <!--  纯日期时间展示，鼠标移出后隐藏  -->
                        <div class="statistic-item">
                          <div class="item-title">
                            {{ handleReminderTime(itemBir.reminderTime) }}
                          </div>
                        </div>
                        <!-- v-if="item.isShow" -->
                        <div class="ribbon">
                          <a-tooltip placement="top" v-if="itemBir.completed">
                            <template #title>
                              <span>撤回完成</span>
                            </template>
                            <div
                              class="dropDown"
                              @click="handleCheck(listItem, itemBir, false)"
                            >
                              <RedoOutlined class="editImg" />
                            </div>
                          </a-tooltip>
                          <a-tooltip placement="top" v-else>
                            <template #title>
                              <span>完成待办</span>
                            </template>
                            <div
                              class="gouGou"
                              @click="handleCheck(listItem, itemBir, true)"
                            >
                              <CheckOutlined class="gou" />
                            </div>
                          </a-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </a-list>
    </a-checkbox-group>
  </div>

  <!-- 保持联系  -->
  <div class="list-wrapperF">
    <div class="reminderTitle">
      <div class="title">
        保持联系（{{ contactData.length + nextWeekData.length }}）
      </div>
      <div class="gotoAll" @click="gotoAll('/contacts/contactFrequency')">
        查看全部
      </div>
    </div>
    <!--   本周  -->
    <a-checkbox-group
      v-model:value="selectedKeys"
      @change="handleChange"
      style="width: 100%"
    >
      <a-list
        item-layout="horizontal"
        :loading="loading"
        :data-source="contactData"
      >
        <template #renderItem="{ item }">
          <a-list-item
            @mouseenter="enterContactData(contactData, item)"
            @mouseleave="leaveContactData(contactData, item)"
            :class="{ showBac: item.isShow }"
          >
            <a-list-item-meta>
              <template #title>
                <div class="titleName">
                  <div class="avatarF">
                    <a-avatar
                      v-if="item.avatar"
                      :src="setAvatar(item.avatar)"
                      @click="handleGoToContact(item)"
                      class="headerUrl"
                    />
                    <div
                      v-else
                      @click="handleGoToContact(item)"
                      class="headerText"
                    >
                      <div v-if="item.name">
                        {{ item.name[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="listNameBirthday">
                    {{ item.name }}
                  </div>
                  <div class="avatarClassCC">
                    <div class="textMl mr-24">
                      {{ item.company }}
                    </div>
                  </div>
                  <div class="avatarClassCC">
                    <div class="textMl mr-8">
                      {{ item.position }}
                    </div>
                  </div>

                  <!--
          frequencyNext 11-本周 21-本月 31-本季度 41-下半年 51-本年
           12-下周 22-下月 32-下季度 42-下半年 52-下年
          -->
                  <div class="avatarClassF mr-24">
                    <div class="avatarClassFWO">
                      <div class="textMl">
                        {{ handleNext(item.frequencyNext) }}
                      </div>
                    </div>
                  </div>
                  <!-- <div class="avatarClassF mr-24">
                    <div class="avatarClassFW" @click="handleOpenPinci(item)">
                      <div class="textMl">
                        {{ handlePinCi(item.frequency) }}
                      </div>
                      <div>
                        <img
                          src="@/assets/images/reminder/shou.png"
                          alt=""
                          class="frequencyImage"
                        />
                      </div>
                    </div>
                  </div> -->
                </div>
              </template>

              <template #description>
                <!--          todo 头像放置处     -->
                <div class="description">
                  <div class="pinkC">
                    <!--  纯日期时间展示，鼠标移入后隐藏   -->
                    <div class="statistic-item">
                      <div class="item-title">
                        {{ handleReminderTimeBir(item?.lastInteractionTime) }}
                      </div>
                    </div>
                    <div class="ribbonC">
                      <!--            加号，点击添加待办        -->
                      <a-tooltip placement="top">
                        <template #title>
                          <span>创建待办</span>
                        </template>
                        <div class="gouGou">
                          <PlusOutlined
                            class="gou"
                            @click="handleEdit(item, 'add', 'week')"
                          />
                        </div>
                      </a-tooltip>
                      <!--            勾 todo 调取新增待办清单的弹窗-- 展浩那边          -->
                      <a-tooltip placement="top">
                        <template #title>
                          <span>完成待办</span>
                        </template>
                        <div class="gouGou" @click="handleGetou(item)">
                          <CheckOutlined class="gou" />
                        </div>
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-checkbox-group>
    <!--  下周  -->
    <div class="nextData">
      <a-checkbox-group
        v-model:value="selectedKeys"
        @change="handleChange"
        style="width: 100%"
      >
        <a-list
          item-layout="horizontal"
          :loading="loading"
          :data-source="nextWeekData"
        >
          <template #renderItem="{ item }">
            <a-list-item
              @mouseenter="enterNextWeekData(nextWeekData, item)"
              @mouseleave="leaveNextWeekData(nextWeekData, item)"
              :class="{ showBac: item.isShow }"
            >
              <a-list-item-meta>
                <template #title>
                  <div class="titleName">
                    <div class="avatarF">
                      <a-avatar
                        v-if="item.avatar"
                        :src="setAvatar(item.avatar)"
                        @click="handleGoToContact(item)"
                        class="headerUrl"
                      />
                      <div
                        v-else
                        @click="handleGoToContact(item)"
                        class="headerText"
                      >
                        <div v-if="item.name">
                          {{ item.name[0] }}
                        </div>
                      </div>
                    </div>

                    <div class="listNameBirthday">
                      {{ item.name }}
                    </div>

                    <div class="avatarClassCC">
                      <div class="textMl mr-24">
                        {{ item.company }}
                      </div>
                    </div>
                    <div class="avatarClassCC">
                      <div class="textMl mr-8">
                        {{ item.position }}
                      </div>
                    </div>

                    <!--
                      frequencyNext 11-本周 21-本月 31-本季度 41-下半年 51-本年

                        12-下周 22-下月 32-下季度 42-下半年 52-下年 NextDate
                    -->
                    <div class="avatarClassF mr-24">
                      <div class="avatarClassFWO">
                        <div class="textMl">
                          {{ handleNextDate(item.frequencyNext) }}
                        </div>
                      </div>
                    </div>
                    <!-- <div class="avatarClassF mr-24">
                      <div class="avatarClassFW" @click="handleOpenPinci(item)">
                        <div class="textMl">
                          {{ handlePinCi(item.frequency) }}
                        </div>
                        <div>
                          <img
                            src="@/assets/images/reminder/shou.png"
                            alt=""
                            class="frequencyImage"
                          />
                        </div>
                      </div>
                    </div> -->
                  </div>
                </template>

                <template #description>
                  <!--          todo 头像放置处     -->
                  <div class="description">
                    <div class="pinkC">
                      <!--  纯日期时间展示，鼠标移入后隐藏   -->
                      <div class="statistic-item">
                        <div class="item-title">
                          {{ handleReminderTimeBir(item?.lastInteractionTime) }}
                        </div>
                      </div>
                      <div class="ribbonC">
                        <!--            加号，点击添加待办        -->
                        <a-tooltip placement="top">
                          <template #title>
                            <span>创建待办</span>
                          </template>
                          <div class="gouGou">
                            <PlusOutlined
                              class="gou"
                              @click="handleEdit(item, 'add', 'nextWeeked')"
                            />
                          </div>
                        </a-tooltip>
                        <!--            勾 todo 调取新增待办清单的弹窗-- 展浩那边          -->
                        <a-tooltip placement="top">
                          <template #title>
                            <span>完成待办</span>
                          </template>
                          <div
                            class="gouGou"
                            @click="handleGetou(item, 'nextWeeked')"
                          >
                            <CheckOutlined class="gou" />
                          </div>
                        </a-tooltip>
                      </div>
                    </div>
                  </div>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </a-checkbox-group>
    </div>
  </div>

  <!-- 联系人新增和编辑待办弹窗  -->
<!--  <add-menber-page-->
<!--    v-model:visible="editAddPage"-->
<!--    :init="activeItemEditAdd"-->
<!--    @fnOk="handleEditPage"-->
<!--  />-->

<!--  待办编辑弹窗  -->
  <edit-page
    v-model:visible="editPage"
    :init="activeItem"
    @fnOk="handleEditPage"
  />

  <!--   联系人待办详情 -->
  <todo-details
    v-model:visible="opneTodoDetails"
    :init="todoDetailsItem"
    @fnOk="handleTodoDetails"
  />
  <!--  人脉圈待办弹窗   -->
  <cr-edit-page
    v-model:visible="editCrtPage"
    :init="activeItemCr"
    @fnOk="handleEditPageCr"
  />

  <!--  人脉圈待办详情弹窗   -->
  <cr-todo-details
    v-model:visible="opneCrTodoDetails"
    :init="todoDetailsItemCr"
    @fnOk="handleTodoDetailsCr"
  />

  <add-birthday
    v-model:visible="addBirthdayPage"
    :init="activeItemaddBirthday"
    @fnOk="handleaddBirthday"
  />
  <frequency-two
    v-model:visible="visibleFrequency"
    :init="activeItemFrequency"
    @fnOk="handleFrequencyPage"
  />
  <add-modal
    v-model:visible="addPage"
    :init="activeAddItem"
    @fnOk="handleAddPage"
  />


</template>

<script>
import {
  computed,
  createVNode,
  getCurrentInstance,
  onBeforeUnmount,
  // getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  Checkbox,
  List,
  message,
  Modal,
  Select,
  DatePicker,
  Space,
  Avatar,
  Tooltip,
  Empty,
} from 'ant-design-vue';
import {
  PlusOutlined,
  CheckOutlined,
  CalendarOutlined,
  CaretDownOutlined,
  RedoOutlined,
} from '@ant-design/icons-vue';
import todayApi from '@/api/today';
import { local } from '@/utils/storage';
import { setAvatar } from '@/utils';
import * as icons from '@ant-design/icons-vue';
import editPage from '@/views/today/homePage/components/editPage';
import todoDetails from '@/views/today/homePage/components/todoDetails';
import addBirthday from '@/views/today/birthday/addBirthday';
import contactAll from '@/api/contactAll';
import frequencyTwo from '@/views/today/homePage/components/frequencyTwo';
import addModal from '@/views/today/homePage/components/addModal';
// 人脉圈编辑和详情
import crEditPage from '@/views/contactsCircle/detailsPage/components/crEditPage';
import crTodoDetails from '@/views/contactsCircle/detailsPage/components/crTodoDetails';
import contactsCircleApi from '@/api/contactsCircle';
import addMenberPage from '@/views/today/homePage/components/addMenberPage';

export default {
  components: {
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    ASpace: Space,
    ACheckbox: Checkbox,
    CheckOutlined,
    PlusOutlined,
    editPage,
    todoDetails,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AAvatar: Avatar,
    addBirthday,
    frequencyTwo,
    addModal,
    CalendarOutlined,
    crEditPage,
    crTodoDetails,
    CaretDownOutlined,
    ATooltip: Tooltip,
    RedoOutlined,
    Empty,
    addMenberPage,

  },
  props: {
    type: String,
    search: Object,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      loading: false,
      showEditDelete: false,
      editPage: false,
      activeItem: {},
      opneTodoDetails: false,
      todoDetailsItem: {},
      editCrtPage: false,
      activeItemCr: {},
      opneCrTodoDetails: false,
      todoDetailsItemCr: {},
      dataSource: [],
      birthdayData: [],
      contactData: [],
      nextWeekData: [],
      searchDate: {
        startTime: null,
        endTime: null,
      },
      selectedKeys: [],
      current: 1,
      size: 10,
      total: 0,
      showTime: false,
      content: '',
      searchType: 'nextSevenDays',
      addBirthdayPage: false,
      activeItemaddBirthday: {},
      //  逾期底纹颜色
      showCurrentBac: null,
      visibleFrequency: false,
      activeItemFrequency: [],
      addPage: false,
      activeAddItem: {},
      listId: '',
    });
    const form = reactive({
      time: '',
      dateStrings: [],
    });

    const computedState = () => {
      if (state.dataSource.length > 0) {
        return {
          current: state.current,
          pageSize: state.size,
          total: state.total,
          showLessItems: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => {
            console.log('total', total, range);
            return `共 ${total} 条，当前显示 ${range[0]}-${range[1]} 条`;
          },
          onChange: (current, pageSize) => initList(current, pageSize),
          onShowSizeChange: (current, pageSize) => initList(current, pageSize),
        };
      }
    };
    // 默认未来7天日期
    const getNextSevenDays = () => {
      state.searchType = 'nextSevenDays';
      // 未来7天 nextSevenDays
      let today = new Date();
      // 格式化日期为 "YYYY-MM-DD" 的字符串
      let formattedDate = today.toISOString().split('T')[0];
      // 复制当前日期
      let seventhDay = new Date(today);
      // 增加七天
      seventhDay.setDate(today.getDate() + 6);
      // 格式化日期为 "YYYY-MM-DD" 的字符串
      let formattedSeventhDay = seventhDay.toISOString().split('T')[0];
      // 创建包含今天和第七天日期的数组
      form.dateStrings = [formattedDate, formattedSeventhDay];
    };

    const pagination = computed(() => {
      return computedState();
    });

    const initList = (current, size) => {
      state.loading = true;
      state.searchType !== 'nextSevenDays'
        ? state.searchType
        : getNextSevenDays();

      state.current = current;
      state.size = size;
      // recentlyTodoPage contactTodoMatterPage
      // 查询最近待办
      todayApi
        .todoPage(route.path, {
          userId: store.state.account.user.id,
          contactId: route.params.id,
          completed: false,
          current,
          size,
          ...props.search,
          ...state.searchDate,
          // content: state.content,
          localDateList: form.dateStrings,
          dateType: state.searchType,
          storageType: 1,
        })
        .then(({ records, total }) => {
          state.dataSource = records;
          // 新增一个变量来改变鼠标移入状态
          state.dataSource.map(item => {
            item.isShow = false;

            let strDate = new Date(item.reminderTime);
            let currentDate = new Date();
            if (strDate < currentDate) {
              item.showLate = true;
            } else {
              item.showLate = false;
            }
          });
          state.total = parseInt(total);
          state.selectedKeys = [];
          if (state.dataSource.length > 0) {
            return local.set('dataSource', true);
          } else {
            return local.set('dataSource', false);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    // 最近联系频次和最近生日分页
    const initListT = (current, size) => {
      state.loading = true;
      state.current = current;
      state.size = size;

      // 获取三十天数组
      let today = new Date();
      let todayFormatted = today.toISOString().split('T')[0];
      let thirtiethDay = new Date(today);
      thirtiethDay.setDate(today.getDate() + 29);
      let formattedThirtiethDay = thirtiethDay.toISOString().split('T')[0];
      let ary = [todayFormatted, formattedThirtiethDay];

      // 查询生日
      todayApi
        .getContactByRecentBirthday(route.path, {
          userId: store.state.account.user.id,
          contactId: route.params.id,
          completed: false,
          current,
          size,
          // ...props.search,
          localDateList: ary,
          content: state.content,
        })
        .then(({ records, total }) => {
          console.log('birthdayData', records);
          state.birthdayData = records;

          // 新增一个变量来改变鼠标移入状态
          state.birthdayData.map(item => {
            item.isShow = false;
            // item.showEditEelBut = false;
          });
          state.total = parseInt(total);
          state.selectedKeys = [];

          if (state.birthdayData.length > 0) {
            return local.set('birthdayData', true);
          } else {
            return local.set('birthdayData', false);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    // {
    //   "customDate": "2024-1-29",
    //     "userId":1739120986162249729,
    //     "current":1,
    //     "size":10
    // }
    // 参数传这些，customDate是当前日期

    // 保持联系本周
    const initListF = (current, size) => {
      state.loading = true;
      state.current = current;
      state.size = size;
      let today = new Date();
      let todayFormatted = today.toISOString().split('T')[0];

      // 复制当前日期
      let seventhDay = new Date(today);
      // 增加七天
      seventhDay.setDate(today.getDate() + 2);
      // 格式化日期为 "YYYY-MM-DD" 的字符串
      let formattedSeventhDay = seventhDay.toISOString().split('T')[0];
      console.log('2-3', formattedSeventhDay);
      // 保持联系本周
      todayApi
        .getContactRecentlyCurrentDate('', {
          userId: store.state.account.user.id,
          customDate: todayFormatted,
          current,
          size,
          queryType: 0,
        })
        .then(({ records, total }) => {
          console.log('contactData', records);
          state.contactData = records;
          // 新增一个变量来改变鼠标移入状态
          state.birthdayData.map(item => {
            item.isShow = false;
            // item.showEditEelBut = false;
          });
          state.total = parseInt(total);
          state.selectedKeys = [];
          if (state.contactData.length > 0) {
            return local.set('contactData', true);
          } else {
            return local.set('contactData', false);
          }
        })
        .finally(() => {
          state.loading = false;
        });

      // 保持联系下周 待联调 联调暂时用这个 formattedSeventhDay
      //  todayFormatted
      todayApi
        .getContactRecentlyNextDate('', {
          userId: store.state.account.user.id,
          customDate: todayFormatted,
          current,
          size,
          queryType: 1,
        })
        .then(({ records, total }) => {
          console.log('nextWeekData', records);
          state.nextWeekData = records;
          // 新增一个变量来改变鼠标移入状态
          state.nextWeekData.map(item => {
            item.isShow = false;
            // item.showEditEelBut = false;
          });
          state.total = parseInt(total);
          state.selectedKeys = [];
          if (state.nextWeekData.length > 0) {
            return local.set('nextWeekData', true);
          } else {
            return local.set('nextWeekData', false);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    watch([props.search, () => props.type], () => initList(1, state.size));
    initList(state.current, state.size);
    initListT(state.current, state.size);
    initListF(state.current, state.size);

    onMounted(() => {
      // document.addEventListener('click', colseSift);
      proxy.$bus.on('getAddData', value => {
        console.log('getAddData', value);
        if (value) {
          initList(1, state.size);
          initListT(state.current, state.size);
          initListF(state.current, state.size);
        }
      });

      proxy.$bus.on('sxOnePage', value => {
        if (value) {
          initList(1, state.size);
          initListT(state.current, state.size);
          initListF(state.current, state.size);
        }
      });

      // state.searchType = 'nextSevenDays';
      // handleData('nextSevenDays');
    });
    // // 在页面注销前，将点击事件给移除
    onBeforeUnmount(() => {
      // document.removeEventListener('click', colseSift);
    });

    const rules = {
      time: [
        {
          required: true,
          message: '不能为空',
          trigger: 'change',
        },
      ],
    };

    // 处理日期筛选
    const handleData = value => {
      // todo 根据选择的下拉值，获取当前时间戳，去计算出 今天，明天，未来7天，未来30天，自定义
      // 获取今天的日期
      let today = new Date();
      // 格式化日期为 "YYYY-MM-DD" 的字符串
      let formattedDate = today.toISOString().split('T')[0];

      // 计算明天的日期
      let tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      // 格式化明天的日期为 "YYYY-MM-DD" 的字符串，并添加到数组中
      let tomorrowFormatted = tomorrow.toISOString().split('T')[0];

      if (value === 'today') {
        form.dateStrings = [formattedDate, formattedDate];
      }
      if (value === 'tomorrow') {
        // 明天
        form.dateStrings = [tomorrowFormatted, tomorrowFormatted];
      }
      if (value === 'nextSevenDays') {
        // 未来7天 nextSevenDays
        // 复制当前日期
        let seventhDay = new Date(today);
        // 增加七天
        seventhDay.setDate(today.getDate() + 6);
        // 格式化日期为 "YYYY-MM-DD" 的字符串
        let formattedSeventhDay = seventhDay.toISOString().split('T')[0];
        // 创建包含今天和第七天日期的数组
        form.dateStrings = [formattedDate, formattedSeventhDay];
      }
      if (value === 'nextThirtyDays') {
        // 未来30天
        // 复制当前日期
        let thirtiethDay = new Date(today);
        thirtiethDay.setDate(today.getDate() + 29);
        let formattedThirtiethDay = thirtiethDay.toISOString().split('T')[0];
        // 创建包含今天和第30天日期的数组
        form.dateStrings = [formattedDate, formattedThirtiethDay];
      }
      if (value === 'custom') {
        // 自定义 直接获取日期赋值
        form.dateStrings = [];
      }
      initList(1, state.size);
    };
    // todo 保存联系人频次忽略记录表实体
    const contactList = item => {
      const now = new Date();
      const year = now.getFullYear();
      const month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : '0' + (now.getMonth() + 1);
      const day = now.getDate() >= 10 ? now.getDate() : '0' + now.getDate();
      const hour = now.getHours() >= 10 ? now.getHours() : '0' + now.getHours();
      const minute =
        now.getMinutes() >= 10 ? now.getMinutes() : '0' + now.getMinutes();
      const second = '00';
      let isTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

      todayApi
        .contactFrequencyRecords('', {
          userId: store.state.account.user.id,
          contactId: item.id,
          checkTime: isTime,
        })
        .then(res => {
          if (res) {
            // 刷新列表
            // initListT(1, state.size);
            localStorage.removeItem('nextWeeked');
            localStorage.removeItem('handleAddPage');
            localStorage.removeItem('weekendData');
            state.editPage = false;
            initList(1, state.size);
            initListF(state.current, state.size);
          }
        });
    };

    return {
      pagination,
      ...toRefs(state),
      setAvatar,
      icons,
      rules,
      form,
      // 0个人1脉圈2逾期， 不传就是全部
      // 今天、明天、未来7天、未来30天、自定义
      items: [
        { value: 'today', label: '今天' },
        { value: 'tomorrow', label: '明天' },
        { value: 'nextSevenDays', label: '未来7天' },
        { value: 'nextThirtyDays', label: '未来30天' },
        { value: 'custom', label: '自定义' },
      ],
      handleName(item) {
        console.log('handleName', item);
        // todo 人脉圈删除异常场景 需要改调接口，

        if (item.personnelType === 1) {
          state.opneCrTodoDetails = true;
          Object.assign(state.todoDetailsItemCr, item);
        } else {
          Object.assign(state.todoDetailsItem, item);
          // 跟联系人详情页面同一个弹窗
          state.opneTodoDetails = true;
        }
      },

      handleExport(item) {
        state.dataSource.map(i => {
          if (i.id === item.id) {
            state.showEditDelete = !state.showEditDelete;
            // i.showEditEelBut = !i.showEditEelBut;
          }
        });
        // console.log('records66', state.dataSource);
      },
      handleGoToContact(record, type) {
        // router.push(`/contacts/${record.id}`);
        if (type === 1) {
          // 点击脉圈跳转去脉圈详情
          router.push(`/contactsCircle/${record.parentId}/${record.id}`);
        } else {
          router.push(`/contacts/${record.id}`);
        }
      },

      handleChange: selectedKeys => {
        if (selectedKeys.length) {
          if (selectedKeys.length === state.dataSource.length) {
            state.checkAll = true;
          } else {
            state.checkAll = false;
          }
        } else {
          state.checkAll = false;
        }
      },

      getStatus: item => {
        const now = new Date().getTime();
        const startTime = new Date(item.startTime).getTime();
        const endTime = new Date(item.endTime).getTime();
        if (now < startTime) return 0;
        if (now > endTime) return 1;
      },

      enter: item => {
        state.dataSource.map(i => {
          if (i.id === item.id) {
            // i.isShow = true;
            i.isShow = !i.isShow;
          }
        });
      },

      leave: item => {
        // console.log('触发了mouseleave 鼠标移出事件！', item);
        state.dataSource.map(i => {
          if (i.id === item.id) {
            i.isShow = false;
            // 鼠标移出后关闭下拉
            // state.showEditDelete = false
          }
        });
      },

      enterBirthday: item => {
        // console.log('触发了 enterBirthday 鼠标移入事件！', item);
        // console.log('item', item);
        // state.dataSource

        state.birthdayData.map(i => {
          if (i.id === item.id) {
            // i.isShow = true;
            i.isShow = !i.isShow;
          }
        });
        // console.log('state.birthdayData', state.birthdayData)
      },
      //
      leaveBirthday: item => {
        // console.log('触发了 leaveBirthday 鼠标移出事件！', item);
        state.birthdayData.map(i => {
          if (i.id === item.id) {
            i.isShow = false;
          }
        });
      },

      // 鼠标移入生日主待办展示子级
      enterBirthdayC: (list, item) => {
        list.map(i => {
          if (i.id === item.id) {
            // i.showBir = true;
            i.showBir = !i.showBir;
          }
        });
      },
      //
      leaveBirthdayC: (list, item) => {
        list.map(i => {
          if (i.id === item.id) {
            i.showBir = false;
          }
        });
      },

      // 保持联系本周列表鼠标移入事件
      enterContactData: (list, item) => {
        list.map(i => {
          if (i.id === item.id) {
            i.isShow = true;
          }
        });
      },

      leaveContactData: (list, item) => {
        // console.log('触发了mouseleave 鼠标移出事件！', item);
        list.map(i => {
          if (i.id === item.id) {
            i.isShow = false;
            // 鼠标移出后关闭下拉
            // state.showEditDelete = false
          }
        });
      },

      // 保持联系下周列表鼠标移入事件
      enterNextWeekData: (list, item) => {
        list.map(i => {
          if (i.id === item.id) {
            i.isShow = true;
          }
        });
      },

      leaveNextWeekData: (list, item) => {
        // console.log('触发了mouseleave 鼠标移出事件！', item);
        list.map(i => {
          if (i.id === item.id) {
            i.isShow = false;
            // 鼠标移出后关闭下拉
            // state.showEditDelete = false
          }
        });
      },

      // 打开编辑弹窗
      handleEdit(item, type, weekend) {
        console.log('handleEdit12', item, weekend);
        if (weekend) {
          if (weekend === 'nextWeeked') {
            // 下周
            local.set('nextWeeked', { ...item, nextWeeked: weekend });
          } else {
            // 本周
            local.set('weekendData', item);
          }
        }

        // 点击编辑时 todo 查看当前时人脉圈还是联系人,
        //  如果 人脉圈connections是null并且查询接口返回是true， 则打开联系人待办弹窗
        // local.set('weekendData', item);
        let parmas = {
          ...item,
          type: type,
          weekend: weekend,
        };

        if (item.personnelType === 1) {
          // 如果是脉圈，则调取查询接口，如果返回true，则打开脉圈编辑，否则打开联系人编辑
          contactsCircleApi.getTitleName('', item.connectionsId).then(res => {
            if (res) {
              state.editCrtPage = true;
              Object.assign(state.activeItemCr, item);
            } else {
              state.editPage = true;
              Object.assign(state.activeItem, item);
            }
          });
        } else {
       console.log('today', parmas)
          state.editPage = true;
          Object.assign(state.activeItem, parmas);
        }
      },

      handleDelete(item) {
        Modal.confirm({
          title: `您确定要删除此待办吗？所有相关数据都将被删除，并且此操作无法撤消。`,
          icon: createVNode(icons['ExclamationCircleOutlined']),
          okType: '',
          okText: '确认',
          onOk: () => {
            todayApi
              .delteMatter('resources:delete', { ids: [item.id] })
              .then(res => {
                if (res) {
                  message.success('操作成功');
                  initList(1, state.size);
                  initListT(1, state.size);
                }
              });
          },
        });
      },
      handleTime(item) {
        state.showTime = !state.showTime;
        console.log('handleTime', item);
      },
      handleGou(item) {
        // console.log('handleGou555', item);
        // 调取主待办完成接口
        todayApi
          .markTodoCompleted('', {
            userId: store.state.account.user.id,
            ...item,
            id: item.id,
            completed: true,
          })
          .then(res => {
            if (res) {
              message.success('操作成功');
              // 刷新列表
              initList(1, state.size);
              initListF(1, state.size);
              initListT(1, state.size);
            }
          });
      },
      handleOpenAddBirthday(item, type) {
        state.addBirthdayPage = true;
        state.activeItemaddBirthday = {
          ...item,
          type: type,
        };
      },
      handleCheck(item, listItem, completeState) {
        console.log('handleCheck5555', item, listItem);
        // 调取子待办完成接口
        todayApi
          .markTodoSubItemsCompleted('', {
            userId: store.state.account.user.id,
            todoId: item.id,
            id: listItem.id,
            completed: completeState,
          })
          .then(res => {
            if (res) {
              message.success('操作成功');
              // 刷新列表
              // initList(1, state.size);
              // initListT(1, state.size);
              todayApi
                .queryTodoSubItem('', {
                  id: listItem.id,
                })
                .then(res => {
                  listItem.subContent = res.subContent;
                  listItem.completed = res.completed;
                });
            }
          });
      },
      gotoAll(path) {
        if (path === '/today/schedule') {
          // 点击路由拿到searchType 存起来，然后到页面调取计算日期
          // handleData(state.searchType);
          router.push(`/today/schedule?scheduleData=${state.searchType}`);
        } else {
          router.push(path);
        }
      },
      // todo 需要区分是不是下周的新增弹窗
      handleEditPage(value) {
        state.editPage = value;
        initList(1, state.size);
        initListF(state.current, state.size);

        let data = local.get('nextWeeked');
        // 下周需要调取联系人频次忽略记录
        if (data.nextWeeked === 'nextWeeked') {
          console.log('拿到nextWeeked', data);
          // contactList(data);
        }
      },

      handleAddPage(value) {
        state.addPage = value;
        // 修改联系人最后互动时间
        // 调取联系人修改最后互动时间的接口

        // 下周不用调修改联系人最后互动时间
        let getData = local.get('handleAddPage');
        console.log('addPage56', value, getData);
        if (getData.nextWeeked === 'nextWeeked') {
          // console.log('111', );
          // contactList(getData);
        } else {
          // console.log('222', );
          // 本周的保持联系需要修改联系人最后互动时间
          const now = new Date();
          const year = now.getFullYear();
          const month =
            now.getMonth() + 1 >= 10
              ? now.getMonth() + 1
              : '0' + (now.getMonth() + 1);
          const day = now.getDate() >= 10 ? now.getDate() : '0' + now.getDate();
          const hour =
            now.getHours() >= 10 ? now.getHours() : '0' + now.getHours();
          const minute =
            now.getMinutes() >= 10 ? now.getMinutes() : '0' + now.getMinutes();
          const second = '00';
          getData.lastInteractionTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

          //
          // contactAll
          //   .contact('', { ...getData })
          //   .then(res => {
          //     if (res) {
          //       console.log('res', res);
          //       localStorage.removeItem('handleAddPage');
          //       // message.success('操作成功');
          //     }
          //   })
          //   .finally(() => {});

        }

        initListF(state.current, state.size);
      },
      handleTodoDetails(value) {
        state.opneTodoDetails = value;
        // 刷新列表
        initList(1, state.size);
      },
      handleEditPageCr(value) {
        state.editCrtPage = value;
        // 刷新列表
        initList(1, state.size);
      },

      handleTodoDetailsCr(value) {
        state.opneCrTodoDetails = value;
        // 刷新列表
        initList(1, state.size);
        initListT(1, state.size);
        initListF(1, state.size);
      },
      // 生日待办弹窗
      handleaddBirthday(value) {
        state.addBirthdayPage = value;
        // 刷新列表
        initListT(1, state.size);
        initList(1, state.size);
        initListF(1, state.size);
      },
      handlePinCi(frequency) {
        if (frequency === '80') {
          return '无';
        }
        if (frequency === '10') {
          return '每周';
        }
        if (frequency === '20') {
          return '每月';
        }
        if (frequency === '30') {
          return '每季度';
        }
        if (frequency === '40') {
          return '每半年';
        }
        if (frequency === '50') {
          return '每年';
        }
        if (frequency === '60') {
          return '不要保持联系';
        }
      },
      // 本周
      handleNext(frequencyNext) {
        if (frequencyNext === '11') {
          return '本周';
        }
        if (frequencyNext === '21') {
          return '本月';
        }
        if (frequencyNext === '31') {
          return '本季度';
        }
        if (frequencyNext === '41') {
          return '下半年';
        }
        if (frequencyNext === '51') {
          return '本年';
        }
      },
      // 下周
      handleNextDate(frequencyNext) {
        if (frequencyNext === '12') {
          return '下周';
        }
        if (frequencyNext === '22') {
          return '下月';
        }
        if (frequencyNext === '32') {
          return '下季度';
        }
        if (frequencyNext === '42') {
          return '下半年';
        }
        if (frequencyNext === '52') {
          return '下年';
        }
      },
      handleOpenPinci(item) {
        console.log('handleOpenPinci', item);
        state.visibleFrequency = true;
        state.activeItemFrequency = [item.id];
      },

      handleReminderTime(reminderTime) {
        // console.log('item666', reminderTime);

        if (reminderTime) {
          // state.listId = item.id
          let tryDateTime = new Date(reminderTime);
          let currentDateTime = new Date();
          state.showCurrentBac = tryDateTime < currentDateTime;
          // console.log('isBeforeCurrentTime66', state.showCurrentBac);

          // 创建一个日期对象
          let dateObject = new Date(reminderTime);
          // 提取月份、日期、小时和分钟
          let month = dateObject.getMonth() + 1; // 月份从0开始，所以需要加1
          let day = dateObject.getDate();
          let hours = dateObject.getHours();
          let minutes = dateObject.getMinutes();
          // let reminderTime = "2024-01-24 15:37:29"
          // 格式化输出
          let formattedTime = `${month}月${day}日 ${
            hours >= 10 ? hours : '0' + hours
          }:${minutes >= 10 ? minutes : '0' + minutes}`;
          return formattedTime;
        } else {
          return '';
        }
      },
      handleFrequencyPage(value) {
        state.visibleFrequency = value;
        initListF(state.current, state.size);
      },

      handleReminderTimeBir(item) {
        if (item) {
          // 创建一个日期对象
          let dateObject = new Date(item);
          // 提取月份、日期、小时和分钟
          let month = dateObject.getMonth() + 1; // 月份从0开始，所以需要加1
          let day = dateObject.getDate();
          // let hours = dateObject.getHours();
          // let minutes = dateObject.getMinutes();
          // let reminderTime = "2024-01-24 15:37:29"
          // 格式化输出
          let formattedTime = `${month}月${day}日`;
          return formattedTime;
        } else {
          return '';
        }
      },

      handleChangeByDate: (dates, dateStrings) => {
        console.log('dateStrings', dateStrings);
        if (dateStrings[0] === '' && dateStrings[1] === '') {
          state.searchType = null;
        } else {
          form.dateStrings = dateStrings;
          // 下拉框选择时，左侧默认为自定义
          state.searchType = 'custom';
        }
        initList();
      },
      handleGetou: (item, type) => {
        console.log('item', item, type);
        // todo 调取展浩那边的弹窗、调取完毕后再调取
        if (type) {
          state.activeAddItem = { ...item, nextWeeked: type };
          local.set('handleAddPage', { ...item, nextWeeked: type });
        } else {
          state.activeAddItem = item;
          local.set('handleAddPage', item);
        }

        state.addPage = true;

        // return message.error('调取新增待办清单的弹窗-- 展浩那边的弹窗');
      },
      // 勾选完成该联系人今天页面下生日的待办完成
      handleContent: item => {
        console.log('item', item);
        let ids = [];
        item.contactTodoMatterVoList?.map(i => {
          ids.push(i.id);
        });

        // 今天页面生日联系人的勾待办事项已完成-未完成
        todayApi
          .markTodoCompletedByContact('', {
            userId: store.state.account.user.id,
            matterIdList: ids,
            contactId: item.id,
            completed: true,
          })
          .then(res => {
            if (res) {
              message.success('操作成功');
              // 刷新列表
              // initListT(1, state.size);
            }
          });
        //   生日联系人勾选完成底下待办，并不展示在列表
        todayApi
          .birthdayRecords('', {
            userId: store.state.account.user.id,
            contactId: item.id,
          })
          .then(res => {
            if (res) {
              // 刷新列表
              initListT(1, state.size);
            }
          });
      },

      handleSearchTask: value => {
        console.log('jjj', value);
        state.searchType = value;
        handleData(value);
        initList(1, state.size);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.reminderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0px 10px;
  .title {
    margin: 18px 0px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  .gotoAll {
    cursor: pointer;
    color: #ff7b00;
  }
}

.statistic-item {
  //width: 103px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  img {
    width: 16px;
    height: 16px;
    margin-top: 5px;
    object-fit: cover;
  }

  .item-title {
    margin-top: 5px;
    color: @text-color-secondary;
  }
  .item-count {
    text-align: right;
    font-size: 24px;
    color: @primary-color;
  }
}
// :deep(.ant-list-items) {
//   min-height: calc(100vh - 273px);
//   height: calc(100vh - 309px);
//   overflow-y: auto;
// }
.userName {
  padding-left: 32px;
}
.list-time {
  padding-top: 4px;
  img {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    margin-right: 4px;
  }
}
.listNameA {
  //width: 500px;
  width: 90%;
  //width: 350px;
  margin-right: 24px;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/
  margin-left: 10px;
  cursor: pointer;
}
.descriptionA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 500px;
  //width: 671px;
  width: 100%;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/
  .pink {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //width: 340px;
    //width: 400px;
    width: 70%;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .avatarClass {
      //width: 400px;
      //width: 294px;
      margin-right: 2px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      .iconClass {
        min-width: 26px;
      }
      .textMl {
        margin-left: 8px;
        color: rgba(0, 0, 0, 0.9);
        width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .flexCalssA {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .avatarClass {
        //width: 400px;
        //width: 294px;
        margin-right: 2px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .headerText {
          min-width: 26px;
        }
        .textMl {
          margin-left: 8px;
          color: rgba(0, 0, 0, 0.9);
          width: 62%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .actions {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    //align-items: center;
    align-items: baseline;
    cursor: pointer;
    width: 30%;
    //padding-right: 10px;
    .statisticMun {
      margin-right: 12px;
      .item-title {
        .fenbuImg {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
      }
    }
    .ribbon {
      width: 96px;
      margin-right: 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      .dropDown {
        position: relative;
        border-radius: 4px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        .editImg {
          margin-left: 4px;
          width: 16px;
          height: 16px;
        }
        .editDelete {
          background: #fff;
          padding: 8px 16px;
          border-radius: 4px;
          position: absolute;
          top: 26px;
          left: -18px;
          cursor: pointer;
          width: 104px;
          color: #3d3d3d;
          z-index: 2;
          .edit {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .editImg {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
          .delete {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 16px;
            .deleteImg {
              width: 16px;
              height: 16px;
              color: #ff8f1f;
              margin-right: 10px;
            }
          }
        }
      }

      .timeTime {
        border-radius: 4px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        margin: 0px 8px;
        .deleteImg {
          //margin: 0px 12px;
          margin-left: 4px;
          width: 16px;
          height: 16px;
        }
      }

      .gouGou {
        border-radius: 4px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        .gou {
          margin-left: 4px;
        }
      }
      .timeModal {
        position: absolute;
        top: 30px;
        left: -130px;
      }
    }
  }
}

.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.list-wrapper /deep/ .ant-list-item-meta-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-wrapper /deep/.ant-list-item-meta-title {
  width: 45%;
}
.list-wrapper /deep/ .ant-list-item-meta-description {
  width: 55%;
}

.list-wrapperC /deep/ .ant-list-item-meta-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-wrapperC /deep/.ant-list-item-meta-title {
  width: 60%;
}
.list-wrapperC /deep/ .ant-list-item-meta-description {
  width: 40%;
}

.list-wrapperF /deep/ .ant-list-item-meta-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-wrapperF /deep/.ant-list-item-meta-title {
  width: 80%;
}
.list-wrapperF /deep/ .ant-list-item-meta-description {
  //width: 20%;
  width: 20%;
}

.listName {
  //width: 500px;
  width: 90%;
  //width: 350px;
  margin-right: 24px;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/
  margin-left: 10px;
  cursor: pointer;
}
.listNameBirthday {
  //width: 500px;
  //width: 350px;
  margin-right: 24px;
  //white-space: nowrap; /*超出的空白区域不换行*/
  //overflow: hidden; /*超出隐藏*/
  //text-overflow: ellipsis; /*文本超出显示省略号*/
  margin-left: 10px;
  cursor: pointer;
  //max-width: 51%;
  min-width: 10%;
  width: 90px;
}
.pink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //width: 340px;
  //width: 400px;
  width: 70%;
  margin-right: 10px;
  .avatarClass {
    //width: 400px;
    //width: 294px;
    margin-right: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .textMl {
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}
.description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 500px;
  //width: 671px;
  width: 100%;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/

  .avatarClass {
    //width: 400px;
    //width: 294px;
    margin-right: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .textMl {
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .flexCalssA {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .actions {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    //align-items: center;
    align-items: baseline;
    cursor: pointer;
    width: 30%;
    // padding-right: 10px;
    .statisticMun {
      margin-right: 12px;
      .item-title {
        .fenbuImg {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
      }
    }
    .ribbon {
      width: 96px;
      margin-right: 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      .dropDown {
        position: relative;
        border-radius: 4px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        .editImg {
          margin-left: 4px;
          width: 16px;
          height: 16px;
        }
        .editDelete {
          background: #fff;
          padding: 8px 16px;
          border-radius: 4px;
          position: absolute;
          top: 26px;
          left: -18px;
          cursor: pointer;
          width: 104px;
          color: #3d3d3d;
          z-index: 2;
          .edit {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .editImg {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
          .delete {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 16px;
            .deleteImg {
              width: 16px;
              height: 16px;
              color: #ff8f1f;
              margin-right: 10px;
            }
          }
        }
      }

      .timeTime {
        border-radius: 4px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        margin: 0px 8px;
        .deleteImg {
          //margin: 0px 12px;
          margin-left: 4px;
          width: 16px;
          height: 16px;
        }
      }

      .gouGou {
        border-radius: 4px;
        background: #ffffff;
        width: 24px;
        height: 24px;
        .gou {
          margin-left: 4px;
        }
      }
      .timeModal {
        position: absolute;
        top: 30px;
        left: -130px;
      }
    }
  }
}
.showBac {
  background: #fffbf6;
  border-left: 2px solid #ff7b00;

  /deep/ .normal-car-icon {
    transform: rotate(180deg);
    // transition: transform 0.3s;
  }
}
/deep/ .showCac {
  transform: rotate(180deg);
  // transition: transform 0.3s;
}
.yuqiBak {
  background: #fff1f1;
}
.listItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 13px 0px;
  .showBor {
    border-bottom: 1px solid #eeeeee;
  }

  .listItemName {
    width: 524px;
    //margin-left: 0px;
  }
  .showLine {
    text-decoration: line-through;
    color: rgba(102, 102, 102, 0.9);
  }
}
.searchClass {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 25px 0px 0px;
  //position: absolute;
  //right: 55px;
  //top: 24px;
}
.iconClass {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffc85b;
  color: #ffffff;
  text-align: center;
  padding-top: 4px;
}
.personnelVoListCalss {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}
.flexCalssC {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.headerTextC {
  background: #ffc85b;
  margin-right: 2px;
}
// .birContent {
// }
.titleName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
}
.avatarClassC {
  //width: 400px;
  //width: 294px;
  margin-right: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  min-width: 40%;
  .textMl {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.9);
  }
}
.total {
  text-align: right;
  width: 30%;
}
.pinkC {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 70%;
  margin-right: 10px;
  .ribbonC {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .gouGou {
      border-radius: 4px;
      background: #ffffff;
      //background: red;
      width: 24px;
      height: 24px;
      margin-right: 4px;
      .gou {
        margin-left: 6px;
        margin-top: 6px;
      }
    }
  }
}

// todo 生日嵌套样式
.ifMous {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  .ifClass {
    width: 100%;
  }
  .birthdayZhu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 26px 0px;

    .listNameC {
      width: 50%;
      padding-right: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
      cursor: pointer;

      &.showLine {
        text-decoration: line-through;
        color: rgba(102, 102, 102, 0.9);
      }
    }

    .flexCalssC {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      width: 50%;
      .avatarClass {
        //width: 400px;
        //width: 294px;
        margin-right: 2px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        //width: 60%;
        .headerUrl {
          cursor: pointer;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        .textMl {
          margin-left: 8px;
          color: rgba(0, 0, 0, 0.9);
        }
        .headerTextC {
          background: #ffc85b;
          margin-right: 2px;
        }
      }

      .descriptionC {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /* width: 100%; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50%;
        padding-right: 10px;
        .actions {
          display: flex;
          justify-content: flex-end;
          //align-items: center;
          align-items: baseline;
          cursor: pointer;
          width: 30%;
          .statistic-item {
            margin-right: 0px;
          }
          //padding-right: 10px;
          .statisticMun {
            margin-right: 12px;
            .item-title {
              .fenbuImg {
                width: 14px;
                height: 14px;
                margin-right: 4px;
              }
            }
          }
          .ribbon {
            width: 96px;
            margin-left: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            .dropDown {
              position: relative;
              border-radius: 4px;
              background: #ffffff;
              width: 24px;
              height: 24px;
              .editImg {
                margin-left: 4px;
                width: 16px;
                height: 16px;
              }
              .editDelete {
                background: #fff;
                padding: 8px 16px;
                border-radius: 4px;
                position: absolute;
                top: 26px;
                left: -18px;
                cursor: pointer;
                width: 104px;
                color: #3d3d3d;
                z-index: 2;
                .edit {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .editImg {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                  }
                }
                .delete {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 16px;
                  .deleteImg {
                    width: 16px;
                    height: 16px;
                    color: #ff8f1f;
                    margin-right: 10px;
                  }
                }
              }
            }

            .timeTime {
              border-radius: 4px;
              background: #ffffff;
              width: 24px;
              height: 24px;
              margin: 0px 8px;
              .deleteImg {
                //margin: 0px 12px;
                margin-left: 4px;
                width: 16px;
                height: 16px;
              }
            }

            .gouGou {
              border-radius: 4px;
              background: #ffffff;
              width: 24px;
              height: 24px;
              .gou {
                margin-left: 4px;
              }
            }
            .timeModal {
              position: absolute;
              top: 30px;
              left: -130px;
            }
          }
        }
      }
    }
  }
}
.showBir {
  .listItemC {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //padding: 26px 0px;
    padding-bottom: 26px;

    // .listItemName {
    //   width: 524px;
    //   margin-left: 0px;
    // }
    .showLine {
      text-decoration: line-through;
      color: rgba(102, 102, 102, 0.9);
    }
  }
  .showBor {
    border-bottom: 1px solid #eeeeee;
  }
}
.frequencyImage {
  width: 18px;
  height: 18px;
}

.list-wrapperF {
  .titleNameF {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .avatarF {
    min-width: 26px;
  }
  .avatarClassCC {
    margin-right: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    //min-width: 28%;
    //min-width: 200px;
    min-width: 232px;
    width: 270px;
  }
  .headerText {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #ff8f1f;
    color: #ffffff;
    text-align: center;
    min-width: 26px;
  }

  .avatarClassF {
    min-width: 10%;
    cursor: pointer;
    .avatarClassFW {
      margin-right: 2px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 94px;
      background: #fff;
      padding: 0px 8px;
      border: 1px solid #eeeff5;
      border-radius: 4px;
      padding-left: 22px;
    }
    .avatarClassFWO {
      margin-right: 2px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 78px;
      //width: 100px;
      background: #fff;
      padding: 0px 8px;
      border: 1px solid #eeeff5;
      border-radius: 4px;
      padding-left: 22px;
    }
  }
  .description {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.list-wrapperC {
  .headerText {
    min-width: 26px;
  }
  .avatarClassC {
    width: 336px;
  }
}
// 给本周底纹
.list-wrapperF /deep/ .ant-list-item {
  background: #fff7e7;
}
.list-wrapperF /deep/ .ant-list-item-meta-title {
  margin-bottom: 0px;
}

// 下周样式底纹
.nextData /deep/ .ant-list-item {
  background: #e7f4ff;
  //min-height: 52px;
}
// 给保持联系列表固定高度
.list-wrapperF /deep/ .ant-list-item {
  min-height: 52px;
}
.nextData {
  margin-top: 24px;
}
//.list-wrapper /deep/ .ant-list-item:hover {
//  background: #fffbf6;
//  border-left: 2px solid #ff7b00;
//}
.lateBac {
  background: #fff1f1;
}

/deep/ .ant-list-item-meta-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

///deep/ .ant-list-item {
//  padding-left: 10px;
//}

.list-dot-icon {
  width: 14px;
  height: 14px;
  margin-top: 2px;
}
</style>
